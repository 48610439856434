
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'

// @ts-ignore
import VueDatePicker from 'vue2-datepicker'
import moment from 'moment'
import DatePickerDate from '@/models/DatePickerDate'
import DatePicker from '../DatePicker/DatePicker.vue'
import SelectPicker from '../SelectPicker/SelectPicker.vue'

@Component({
  components: {
    SelectPicker,
    DatePicker,
    'v-date-picker': VueDatePicker,
  },
})
export default class DateSelectPicker extends ViewModel {
  @Ref() public picker!: SelectPicker

  @Prop()
  public value!: any

  @Prop()
  public min_date!: any

  @Prop()
  public max_date!: any

  public temp_date: any = null

  public is_managing: boolean = false

  public ready: boolean = false

  public editing_date: any = null

  public settings = {
    disabledDate: (date: any) => {
      if (this.max_date && moment(date).isAfter(moment(this.max_date))) return true
      if (this.min_date && moment(date).isBefore(moment(this.min_date))) return true
      if (moment(date).day() !== 1) return true
      if (this.local_value.includes(moment(date).format('MM/DD/YYYY'))) return true
      return false
    },
  }

  public get local_value() {
    if (!this.value) return []
    return this.value
  }

  public set local_value(value: any) {
    this.$emit('input', value)
  }

  public mounted() {
    this.ready = true
  }

  public addDate() {
    if (!this.temp_date) return

    this.local_value.push(moment(this.temp_date).format('MM/DD/YYYY'))

    if (this.editing_date && this.editing_date.length) {
      this.local_value = this.local_value.filter((d: string) => d !== this.editing_date)
    }

    setTimeout(() => {
      this.editing_date = null
    }, 300)
  }

  public get options() {
    if (!this.local_value || !this.local_value?.length) return []
    return this.local_value.map((date: any) => ({
      name: date,
      value: date,
    }))
  }

  public toggleManage() {
    setTimeout(() => {
      if (this.is_managing) {
        this.addDate()

        Vue.set(this, 'is_managing', false)

        return
      }
      this.editing_date = null
      this.temp_date = null
      this.is_managing = true
    }, 200)
  }

  public edit(week: string) {
    this.editing_date = week
    // week ="02/26/2024" ; to date
    this.temp_date = moment(week, 'MM/DD/YYYY').toDate()
    this.is_managing = true
  }

  public removeDate(date: string) {
    this.local_value = this.local_value.filter((d: string) => d !== date)
  }
}
