import moment from 'moment'
import router from '@/Routes'
import { clone } from 'lodash'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import Model from './interface/Model'
import { tableFields } from './metadata/TaskMetadata'
import User from './User'
import ModelWithFiles from './interface/ModelWithFiles'
import Company from './Company'
import Api from './Api'
import ModelFile from './ModelFile'
import { RegionGroupMap } from './interface/Common'
import Comment from './Comment'

export default class Task extends ModelWithFiles {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'task' as string | null,
      plural: 'tasks' as string | null,
    },
  }

  public name: string = ''

  public description: string = ''

  public owner_id: string = ''

  public owner: User | null = null

  public target_id: any = ''

  public target: User | null = null

  public status: string = 'pending'

  public metadata: any = {
    source: {} as any,
  }

  public messages: any = {}

  public created_at: string = ''

  public owner_model_id: null | string = null

  public owner_model_type: null | string = null

  public updated_at: string = ''

  public completed_at: string | null = ''

  public completed_by_id: any = null

  public completed_by: any = null

  public due_at: string | null = ''

  public type: string = 'custom'

  public _showDetails: boolean = false

  public sub_tasks: Array<any> = []

  public comment_count: number = 0

  public comments: Comment[] = []

  public notes: Array<any> = []

  public additional_information: any = {
    target_audience: '',
    products: ['ssl'],
    deliverable: '',
    additional_notes: '',
    description: '',
    agency_id: '',
    advertiser_id: '',
    project_overview: '',
    format: 'deliverable',
    objective: '',
    key_messages: '',
    research: '',
    mandatory_elements: '',
    targetting: [],
    demo_groups: [],
    amount: 0,
    flight_start: '',
    flight_end: '',
    requests: [],
    pipeline_value: null,
  }

  // Preloaded references
  public references: any = {
    agency_id: null,
    advertiser_id: null,
    products: null,
  }

  public is_editing: boolean = false

  public origin: any = null

  public parent_id: any = null

  public get is_mine() {
    const systemModule = getModule(SystemtModule)
    if (!systemModule?.user?.id) return false
    return this.owner_id === systemModule?.user?.id
  }

  public clear_values(value: any) {
    if (!value) return value
    return value.replaceAll('_', ' ')
  }

  public date_format(date: any) {
    return moment(date).format('MMMM Do, YYYY')
  }

  public findIds(id: string, content: string, ref: any) {
    if (['agency_id', 'advertiser_id'].includes(content)) {
      Company.find(id).then(company => {
        if (!company) {
          return
        }
        ref = company
      })
    }
  }

  public get is_delayed() {
    return this.due_at && moment(this.due_at).isBefore(moment())
  }

  public get status_name() {
    return this.status !== 'completed' && this.is_delayed
      ? `Delayed / ${this.status.replaceAll('_', ' ')}`
      : this.status.replaceAll('_', ' ')
  }

  public get status_color() {
    if (this.status === 'completed') {
      return 'success'
    }

    if (this.is_delayed) {
      return 'danger'
    }
    if (this.status === 'pending') {
      return 'secondary'
    }
    if (this.status === 'in_progress') {
      return 'info'
    }

    return 'warning'
  }

  // public getDescriptionByType() {
  //   if (this.type !== 'custom') return this.dynamicDescription
  //   return this.description
  // }

  public get apiData() {
    const target_id = process.env.VUE_APP_ENV !== 'development' ? this.target_id : RegionGroupMap.marketing_material

    return {
      name: this.name,
      description: this.description == null || this.description == '' ? 'N/A' : this.description,
      owner_id: this.owner_id,
      target_id,
      status: this.status,
      metadata: this.metadata,
      messages: this.messages,
      owner_model_id: this.owner_model_id,
      owner_model_type: this.owner_model_type,
      due_at: this.due_at,
      type: this.type,
      files: this.files,
      origin: this.origin,
      additional_information: this.additional_information,
      parent_id: this.parent_id,
      completed_at: this.completed_at,
      completed_by_id: this.completed_by_id,
    }
  }

  // @ts-ignore
  public toObject(source: any) {
    let instance = new Task()

    instance.metadata = {
      ...instance.metadata,
      ...source.metadata,
    }

    instance.additional_information = {
      ...instance.additional_information,
      ...source.additional_information,
    }

    delete source.metadata
    delete source.additional_information

    if (source.sub_tasks && source.sub_tasks.length) {
      instance.sub_tasks = source.sub_tasks.concat(instance.sub_tasks)
      instance.sub_tasks = instance.sub_tasks.map(sub_task => Task.toObject(sub_task))
      instance._showDetails = true
      delete source.sub_tasks
    }

    Object.assign(instance, source)

    if (source.files) {
      instance.files = ModelFile.toObjectList(source.files)
    }

    if (source.comments) {
      instance.comments = Comment.toObjectList(source.comments)
    }

    return instance
  }

  public createPlan() {
    if (this.type.includes('pre_plan')) {
      router.push(`/app/sales/pre-plan?task=${this.id}`)
    } else if (this.type.includes('media_plan')) {
      router.push(`/app/sales/media_plan?task=${this.id}`)
    }
  }

  public createMaxAvails() {
    if (this.additional_information.requests.includes('max_avail')) {
      router.push(`/app/sales/pre-plan?task=${this.id}`)
    }
  }

  public async loadComments() {
    const api = new Api()

    return api.get(`task-comments/${this.id}`).then(response => {
      this.comments = response.data.result.map((m: any) =>
        Comment.toObject({ ...m, base_path: 'task-' }))
      return this.comments
    })
  }

  public static tableFields: any = tableFields
}
