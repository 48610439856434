export default class Targetting {
  public include_dmas: Array<any> = []

  public exclude_dmas: Array<any> = []

  public include_countries: Array<any> = []

  public exclude_countries: Array<any> = []

  public include_states: Array<any> = []

  public exclude_states: Array<any> = []

  public include_cities: Array<any> = []

  public exclude_cities: Array<any> = []

  public include_zips: Array<any> = []

  public exclude_zips: Array<any> = []

  public include_devices: Array<any> = []

  public exclude_devices: Array<any> = []

  public include_ip: Array<any> = []

  public exclude_ip: Array<any> = []

  public include_key_values: Array<any> = []

  public exclude_key_values: Array<any> = []

  public include_adunits: Array<any> = []

  public exclude_adunits: Array<any> = []

  public zipcode_range: Array<any> = []
}
