import moment from 'moment'
import Api from './Api'
import Model from './interface/Model'
import Targetting from './BasicTargetting'

// export interface PrePlanItemTargetting {
//   include_dmas: Array<any>
//   exclude_dmas: Array<any>
//   include_countries: Array<any>
//   exclude_countries: Array<any>
//   include_states: Array<any>
//   exclude_states: Array<any>
//   include_cities: Array<any>
//   exclude_cities: Array<any>
//   include_zips: Array<any>
//   exclude_zips: Array<any>
//   include_devices: Array<any>
//   exclude_devices: Array<any>
//   include_ip: Array<any>
//   exclude_ip: Array<any>
//   include_key_values: Array<any>
//   exclude_key_values: Array<any>
//   include_adunits: Array<any>
//   exclude_adunits: Array<any>
//   zipcode_range: Array<any>
// }

export default class PrePlanItem extends Model {
  public id: any = null

  public pre_plan: any = null

  public sales_rep_id: any = ''

  public media_package_id: any = null

  public client_name: string = ''

  public client_code: string = ''

  public product_code: string = ''

  public estimate_code: string = ''

  public flight_start: any = moment().add(1, 'months').startOf('month')

  public flight_end: any = moment(this.flight_start).endOf('month')

  public ad_length: any = null

  public gross_rate: any = 0

  public net_rate: any = 0

  public impressions: number = 0

  public targetting: Targetting = new Targetting()

  public demo_groups: any = [{ age_low: '25', age_high: '54', target: 'AD' }]

  public pre_plan_id: any = null

  public pre_plan_type: any = null

  public visible: any = false

  public days_of_week: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

  public skip_weeks: any = []

  public get apiData() {
    return {
      id: this.id,
      sales_rep_id: this.sales_rep_id,
      media_package_id: this.media_package_id,
      estimate_code: this.estimate_code,
      client_name: this.client_name,
      client_code: this.client_code,
      product_code: this.product_code,
      flight_start: this.flight_start,
      flight_end: this.flight_end,
      ad_length: this.ad_length,
      gross_rate: this.gross_rate,
      net_rate: this.net_rate,
      targetting: this.targetting,
      demo_groups: this.demo_groups,
      impressions: this.impressions,
      days_of_week: this.days_of_week,
      skip_weeks: this.skip_weeks,
    }
  }

  public toObject(source: any) {
    let instance = this.clone()

    if (source.days_of_week) {
      instance.days_of_week = source.days_of_week
    }
    delete source.days_of_week

    if (source.skip_weeks) {
      instance.skip_weeks = source.skip_weeks
    }
    delete source.skip_weeks

    Object.assign(instance, source)
    if (source.flight_start) {
      instance.flight_start = source.flight_start.includes('T')
        ? source.flight_start.split('T')[0]
        : moment(source.flight_start).format('YYYY-MM-DD')
    }

    if (source.flight_end) {
      instance.flight_end = source.flight_end.includes('T')
        ? source.flight_end.split('T')[0]
        : moment(source.flight_end).format('YYYY-MM-DD')
    }

    return instance
  }
}
