export default [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    show: true,
    type: 'checkbox',
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
  },
  {
    key: 'id',
    label: 'Row #',
    sortable: false,
    show: true,
    class: 'text-center align-middle text-capitalize',
    thStyle: { width: '200px' },
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
  },
  {
    key: 'client_name',
    label: 'Client',
    sortable: false,
    show: true,
    class: 'text-center align-middle text-capitalize',
    thStyle: { width: '15rem' },
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
  },
  {
    key: 'dma_name',
    label: 'Dma From Sheet',
    sortable: false,
    show: true,
    class: ' align-middle text-capitalize',
    thStyle: { width: '15rem' },
  },
  {
    key: 'dma',
    label: 'Dma Confirm',
    sortable: false,
    show: true,
    class: 'text-center align-middle text-capitalize',
    thStyle: { width: '25rem' },
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
  },
  {
    key: 'zipcode',
    label: 'Zipcode',
    sortable: false,
    show: false,
    class: 'text-center align-middle text-capitalize',
    thStyle: { width: '20rem' },
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
  },
  {
    key: 'range',
    label: 'Range',
    sortable: false,
    show: false,
    class: 'text-center align-middle text-capitalize',
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
    thStyle: { width: '14rem' },
  },
  {
    key: 'action',
    label: '',
    sortable: false,
    show: true,
    class: 'text-center align-middle text-capitalize',
    tdClass: 'align-middle text-center',
    thClass: 'align-middle text-center',
  },
]
