import { clone } from 'lodash'
import { getModule } from 'vuex-module-decorators'
import Api from './Api'
import Model from './interface/Model'

export default class Comment extends Model {
  public api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'comments',
      plural: 'comments',
    },
  }

  public id: string | null = null

  public name: string = ''

  public type: string | null = 'comment'

  public content: any = null

  public owner_model: any = null

  public owner_model_id: any = null

  public owner_model_type: any = null

  public created_at: any = null

  public updated_at: any = null

  public task_id: any = null

  public content_collapsed: boolean = true

  public content_length: any = 0

  public min_content_length: any = 150

  public collapse(state: boolean = false) {
    this.content_collapsed = state
  }

  public get controlledContent() {
    const content = clone(this.content)

    if (this.getTextLength(this.content) >= this.min_content_length) {
      if (this.content_collapsed) {
        return this.truncateHTML(content, this.min_content_length)
      }
      return content
    }
    return content
  }

  public get contentLength() {
    return this.getTextLength(this.content)
  }

  public get start_collapsed() {
    return this.contentLength >= this.min_content_length
  }

  public getTextLength(html: any) {
    let domParser = new DOMParser()
    let doc = domParser.parseFromString(html, 'text/html')
    return doc.body.textContent?.length || 0
  }

  public created_by: any = { name: '' }

  public get created_by_initials() {
    return Comment.getInitials(this.created_by.name)
  }

  public constructor(base_path: any = null) {
    super()

    if (base_path) {
      this.api_settings.paths.singular = `${base_path}comments`
      this.api_settings.paths.plural = `${base_path}comments`
    }
  }

  // @ts-ignore
  public toObject(source: any) {
    let instance = new Comment()
    if (source.base_path) {
      instance.api_settings.paths.singular = `${source.base_path}comments`
      instance.api_settings.paths.plural = `${source.base_path}comments`
    }
    Object.assign(instance, source)

    instance.collapse(true)

    return instance
  }

  public get apiData() {
    return {
      id: this.id,
      task_id: this.task_id,
      name: this.name,
      type: this.type,
      content: this.content,
      owner_model_id: this.owner_model_id,
      owner_model_type: this.owner_model_type,
    }
  }

  public async save() {
    const api = new Api()
    return api.post(`${this.api_settings.paths.singular}${this.id ? `/${this.id}` : ''}`, this.apiData).then(response => response.data.result)
  }

  public static getInitials(name: string) {
    if (!name) return

    const nameParts = name.trim().split(' ')

    const firstInitial = nameParts[0].charAt(0)

    const secondInitial = nameParts.length > 1 ? nameParts[1].charAt(0) : ''

    const initials = firstInitial + secondInitial

    return initials.toUpperCase()
  }

  public async remove() {
    const api = new Api()
    return api.delete(`${this.api_settings.paths.singular}/${this.id}`).then(response => response.data.result)
  }

  public truncateHTML(html: any, maxLength: any) {
    let domParser = new DOMParser()
    const doc = domParser.parseFromString(html, 'text/html')
    let currentLength = 0
    let truncatedHTML = ''

    function getAttributes(node: any) {
      let attrs = []
      for (let attr of node.attributes) {
        attrs.push(`${attr.name}="${attr.value}"`)
      }
      return attrs.length ? ` ${attrs.join(' ')}` : ''
    }

    function traverse(node: any) {
      if (node.nodeType === Node.TEXT_NODE) {
        let text = node.textContent
        if (currentLength + text.length > maxLength) {
          truncatedHTML += text.substring(0, maxLength - currentLength)
          currentLength = maxLength // Update current length to maxLength
        } else {
          truncatedHTML += text
          currentLength += text.length
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        let tag = node.tagName.toLowerCase()
        truncatedHTML += `<${tag}${getAttributes(node)}>`

        for (let i = 0; i < node.childNodes.length; i++) {
          traverse(node.childNodes[i])
          if (currentLength >= maxLength) break
        }

        truncatedHTML += `</${tag}>`
      }
    }

    this.content_length = currentLength
    traverse(doc.body)
    return truncatedHTML
  }
}
