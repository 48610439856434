
import {
  Vue, Prop, Component, Watch, Ref,
} from 'vue-property-decorator'
import DataForm from '@/components/DataForm/DataForm.vue'
import Task from '@/models/Task'

import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import { clone as _clone } from 'lodash'
import IconAction from '@/components/IconAction/IconAction.vue'
import WebMessage from '@/models/WebMessage'
import { EventBus } from '@/plugins/eventBus'
import { RegionGroupMap } from '@/models/interface/Common'
import FormInput from '@/components/FormInput/FormInput.vue'
import formData from '../form'

@Component({
  components: {
    DataForm,
    IconAction,
    FormInput,
  },
})
export default class EditTask extends Vue {
  @Ref() readonly dataForm: any

  @Prop({ default: null })
  public modalAttrs!: any

  @Prop({ default: null })
  public id!: string | null

  @Prop({ default: null })
  public dataTable!: any

  @Prop({ default: null })
  public show!: any

  @Prop({ default: 'view' })
  public mode!: any

  @Prop({ default: () => [] })
  public hideTypes!: any

  @Prop({ default: () => {} })
  public initialPayload!: any

  @Prop({ default: null })
  public parentId!: any

  public task: Task = new Task()

  public busy: boolean = false

  public loading: boolean = false

  public edit: boolean = false

  public parentTask: Task = new Task()

  private get region_map() {
    return RegionGroupMap
  }

  public form = formData

  public get showModal() {
    return this.show === null ? true : this.show
  }

  public set showModal(show: boolean) {
    this.$emit('update:show', show)
  }

  private updateForm(busy: boolean = true) {
    this.busy = busy
    let form = _clone(formData)

    form.sections[0].fields = form.sections[0].fields.map((field: any) => {
      field.edit = this.edit

      if (field.target === 'type' && this.hideTypes.length > 0 && !this.id) {
        field.options = field.options.filter(
          (option: any) => !this.hideTypes.includes(option.value),
        )
      }
      this.loading = false
      return field
    })

    this.form = form
    setTimeout(() => {
      this.busy = false
    }, 100)
  }

  private get user() {
    return getModule(SystemtModule).user
  }

  public editTask() {
    this.edit = true
    this.task.is_editing = true
    this.updateForm()
  }

  public confirm() {
    if (this.edit) {
      this.task.save().then(() => {
        if (this.dataTable) this.dataTable.refresh()
        if (this.show === null) {
          EventBus.$emit('open-task-modal', false)
          this.$destroy()
          this.$el.parentNode?.removeChild(this.$el)
        }
      })
    } else {
      EventBus.$emit('open-task-modal', false)
      this.$destroy()
      this.$el.parentNode?.removeChild(this.$el)
    }
  }

  public cancel() {
    if (this.show === null) {
      this.$emit('close')
      this.$destroy()
      this.$el.parentNode?.removeChild(this.$el)
    }
  }

  @Watch('show')
  public onShowChange() {
    if (this.show) {
      EventBus.$emit('open-task-modal', true)
      this.updateTask()
    }
  }

  @Watch('task.type')
  public onTypeChange(val: any) {
    if (
      (!this.id && this.task.type && this.region_map[this.task.type])
      || (this.user?.region && this.region_map[this.user.region])
    ) {
      if (process.env.VUE_APP_ENV === 'development') {
        this.task.target_id = this.user.id
      } else if (this.task.type === 'marketing_material' || this.task.type === 'marketing_rfp') {
        this.task.target_id = this.region_map[this.task.type]
      } else if (this.initialTarget) {
        this.task.target_id = this.initialTarget
      } else if (this.user?.region && this.region_map[this.user.region]) {
        this.task.target_id = this.region_map[this.user.region]
      }
    }

    if (val) this.$emit('type-change', val)
  }

  public created() {
    if (this.mode === 'edit') {
      this.edit = true
    }
    EventBus.$emit('open-task-modal', true)
    this.updateTask()
  }

  public loadParentTask() {
    if (!this.parentId || !this.task.parent_id) return
    // parentTask
    Task.find(this.parentId || this.task.parent_id).then(task => {
      this.parentTask = task

      if (this.mode === 'edit') {
        if (!this.task.owner_model_type) {
          this.task.owner_model_type = task.owner_model_type
          this.task.owner_model_id = task.owner_model_id
        }
      }
    })
  }

  public updateTask(busy: boolean = true) {
    this.loading = true
    if (this.id) {
      this.busy = busy
      this.edit = this.mode === 'edit'
      Task.find(this.id).then(task => {
        if (!task) {
          this.cancel()
          WebMessage.error('Task not found!')
          return
        }
        this.task = task
        this.updateForm(busy)
        this.loadParentTask()
        this.loading = false
      })
    } else {
      this.task = Task.toObject({ ...this.initialPayload })
      if (this.parentId) {
        this.task.parent_id = this.parentId
      }

      if (!this.task.target_id && this.user && typeof this.user.id === 'string') {
        this.task.target_id = this.user.id
      }

      this.edit = true
      this.task.is_editing = true
      this.updateForm(busy)
      this.loadParentTask()
    }
  }

  public closeModal() {
    this.$emit('close')
    EventBus.$emit('open-task-modal', false)
  }
}
