
import ViewModel from '@/models/ViewModel'
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import FormInput from '@/components/FormInput/FormInput.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import Widget from '@/components/Widget/Widget.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import { currencyMask, customNumber } from '@/models/interface/Masks'
import MediaPackagePicker from '@/components/MediaPackagePicker/MediaPackagePicker.vue'
import GeoTargetting from '@/pages/LineItem/components/Targetting/GeoTargetting.vue'
import DemoTargetBuilder from '@/components/DemoTargetBuilder/DemoTargetBuilder.vue'
import { CreativeLength } from '@/models/interface/Common'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import PrePlan from '@/models/PrePlan'
import OpportunityPicker from '@/components/OpportunityPicker/OpportunityPicker.vue'

import DataTable from '@/components/DataTable/index.vue'
import Task from '@/models/Task'
import Targetting from '@/models/BasicTargetting'
import Company from '@/models/Company'
import DayOfWeek from '@/components/DayOfWeekPicker/DayOfWeek.vue'
import DateSelectPicker from '@/components/DateSelectPicker/DateSelectPicker.vue'
import DeviceCategory from '../LineItem/components/Targetting/DeviceCategory.vue'

import PrePlanFileImport from './components/PrePlanFileImport.vue'

@Component({
  components: {
    FormInput,
    CompanyPicker,
    IconAction,
    FormWizard,
    TabContent,
    Widget,
    UserPicker,
    DatePicker,
    GeoTargetting,
    MediaPackagePicker,
    DemoTargetBuilder,
    SelectPicker,
    FooterNav,
    OpportunityPicker,
    DeviceCategory,
    DataTable,
    PrePlanFileImport,
    DayOfWeek,
    DateSelectPicker,
  },
})
export default class PrePlanGroup extends ViewModel {
  @Ref() private prePlanImporter!: any

  public loading: boolean = true

  public pre_plan: PrePlan = new PrePlan()

  public collapse_event_list: any = {
    geo_targetting: false,
  }

  public current_typed_name_obj: any = {
    name: null,
    index: null,
  }

  public import_status: string = ''

  public imported_file_row_keys: any = null

  public import_modal_title: string = 'Importing Order File'

  public import_errors: any = []

  public busy: boolean = true

  public confirming: boolean = false

  public geoTargettingLoaded: boolean = false

  @Watch('pre_plan.agency_id')
  public onAgencyIdChange(val: any) {
    if (val) {
      Company.find(val).then((res: any) => {
        this.pre_plan.agency = res
      })
    }
  }

  // watch  current_typed_name_obj.name change, give it a delay to avoid too many request
  @Watch('current_typed_name_obj.name')
  public onCurrentTypedNameObjNameChange(val: any) {
    if (val.length > 2) {
      this.checkPlanGroupItems()
    }
  }

  public get IS_CLONNING() {
    return this.$route.query.action && this.$route.query.action === 'clone'
  }

  public get ads_length() {
    return CreativeLength
  }

  public checkPlanGroupItems() {
    if (this.pre_plan.items.length && this.pre_plan.items.length > 1) {
      let filtered = this.pre_plan.items.filter((item, index) => {
        if (
          index !== this.current_typed_name_obj.index
          && this.current_typed_name_obj.name
          && this.current_typed_name_obj.name.length
        ) {
          return (
            item.client_name
              ?.toLowerCase()
              .indexOf(this.current_typed_name_obj.name?.toLowerCase()) > -1
          )
        }
        return false
      })
    }
  }

  public resetSelection() {
    this.$set(this, 'selected_confirm_row', [])
  }

  public get masks() {
    return { currency: currencyMask, customNumber }
  }

  public onSubmit() {
    this.loading = true

    if (this.IS_CLONNING) {
      this.pre_plan.id = null
    }

    this.pre_plan.save().then((res: any) => {
      if (res.status === 200) {
        this.$router.push({ name: 'pre-plans' })
      }
      this.loading = false
    })
  }

  public createFromTask() {
    if (this.$route.query && this.$route.query.task && this.$route.params.id === undefined) {
      // @ts-ignore
      Task.find(this.$route.query.task)
        .then(response => {
          this.pre_plan.agency_id = response.metadata?.context?.agency_id ?? null

          this.pre_plan.opportunity_id = response.metadata.origin_id

          this.pre_plan.items = []

          // TODO are we going to stop using metadata.context???
          setTimeout(() => {
            if (response.additional_information) {
              if (response.additional_information.requests.includes('max_avail')) {
                this.pre_plan.opportunity_id = response.owner_model_id
              }
              this.pre_plan.addItem({
                demo_groups: response.additional_information.demo_groups ?? [
                  { age_low: '25', age_high: '54', target: 'AD' },
                ],
                targetting: Object.assign(
                  new Targetting(),
                  response.additional_information.targetting,
                ),
                visible: true,
                gross_rate: response.additional_information.amount ?? 0,
                products: response.additional_information.products ?? null,
                flight_start: response.additional_information.flight_start,
                flight_end: response.additional_information.flight_end,
              })
            } else {
              this.pre_plan.addItem({
                demo_groups: response?.metadata?.context?.demo_groups ?? [
                  { age_low: '25', age_high: '54', target: 'AD' },
                ],
                targetting: Object.assign(new Targetting(), response.metadata.context.targetting),
                visible: true,
                gross_rate: response.metadata.context.amount ?? 0,
                products: response.metadata.context.products ?? null,
                flight_start: response.metadata.context.flight_start,
                flight_end: response.metadata.context.flight_end,
              })
            }

            this.loading = false
            this.busy = false
          }, 1000)
        })
        .catch(e => {
          this.loading = false
          this.busy = false
        })
    } else {
      this.loading = false
      this.busy = false
    }
  }

  public mounted() {
    if (this.$route.params.id) this.loadData()
    else {
      this.createFromTask()
    }
  }

  public loadData() {
    this.loading = true
    PrePlan.find(this.$route.params.id).then((res: any) => {
      this.pre_plan = res
      if (this.IS_CLONNING) {
        const { name } = this.pre_plan
        this.pre_plan.original_name = name
        this.pre_plan.name = ''
      }
      this.createFromTask()

      this.checkPlanGroupItems()

      this.loading = false
      this.busy = false
    })
  }

  public toggleGeoTargetting(val: boolean) {
    if (!this.geoTargettingLoaded) {
      this.geoTargettingLoaded = true
    }
  }

  public addPreplan(clone: any = null) {
    if (clone) clone.visible = true
    this.pre_plan.addItem(clone)
    setTimeout(() => {
      let index = this.pre_plan.items.length - 1
      const element: any = document.getElementById(`collapse-${index}`)
      if (element) element?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
    }, 200)
  }

  public removePrePlanItem(index: number) {
    this.pre_plan.removeItem(index)
  }

  public toggleAll(target: boolean) {
    this.pre_plan.items.forEach((item: any) => {
      item.visible = target
    })
  }
}
