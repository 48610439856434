
import ViewModel from '@/models/ViewModel'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class DayOfWeek extends ViewModel {
  @Prop()
  public value!: String[]

  @Prop({ default: false })
  public useDefault!: boolean

  @Prop({ default: false })
  public disabled!: boolean

  @Prop({ default: 'days-of-week' })
  public id!: string

  @Prop({ default: 'week days' })
  public name!: string

  @Prop({ default: null })
  public rules: any

  public ready: boolean = false

  public week_days: any = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ]

  public get local_value() {
    return this.value
  }

  public set local_value(value: any) {
    this.$emit('input', value)
  }

  public get valueToString() {
    if (!this.local_value.length) return null
    return this.local_value.join(', ')
  }

  public validateDays() {
    let updated: String[] = []
    this.local_value.forEach(day => {
      day = day.toLowerCase()
      updated.push(this.week_days.find(d => d.startsWith(day)))
    })
    this.local_value = updated
  }

  public mounted() {
    if (this.useDefault && !this.local_value.length) this.local_value = this.week_days
    else this.validateDays()
    this.ready = true
  }

  public toggleDay(day: string) {
    if (this.local_value.includes(day)) {
      this.local_value = this.local_value.filter(d => d !== day)
    } else {
      this.local_value = [...this.local_value, day]
    }
  }

  public emitErrors(errors: any) {
    this.$emit('errors', errors)
    return ''
  }

  public ucFirst(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}
