
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import DataTable from '@/components/DataTable/index.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import DropArea from '@/components/DropArea/DropArea.vue'
import WebMessage from '@/models/WebMessage'
import { currencyMask, customNumber } from '@/models/interface/Masks'
import { read, utils } from 'xlsx'
import dmas from '@/data/dmas'
import fuzzyset from 'fuzzyset'
import Fuse from 'fuse.js'
import PrePlanItem from '@/models/PrePlanItem'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import PrePlan from '@/models/PrePlan'
import BigNumber from 'bignumber.js'
import moment from 'moment'
import User from '@/models/User'
import MediaPackage from '@/models/MediaPackage'
import Company from '@/models/Company'
import SelectOption from '@/models/interface/SelectOption'
import { clone, cloneDeep } from 'lodash'
import preplan_file_import_table_fields from './preplan-file-import-table-fields'

@Component({
  components: {
    DataTable,
    FormInput,
    DropArea,
    IconAction,
    SelectPicker,
  },
})
export default class PrePlanFileImport extends ViewModel {
  @Ref() private drop_area!: DropArea

  @Prop()
  public value: any // must be the preplan object

  @Prop({ default: false })
  public confirming!: boolean // must be the preplan object

  public import_modal_title: string = 'Importing Order File'

  public imported_file_row_keys: any = null

  public loaded_data: any = []

  public import_status: string = ''

  public import_errors: any = []

  public import_error_type: string = 'warning'

  public file_name: any = null

  public selected_confirm_row: string[] = []

  public confirm_data: boolean = false

  public all_selected: boolean = false

  public table_loading: boolean = true

  public loaded_data_fields: any = preplan_file_import_table_fields

  public loading_data: boolean = true

  public reload_drop_down_area: boolean = false

  public dma_list: any = []

  public dma_full_list: any = []

  public temp_zipcodes: any = null

  public dma_options_index: any = []

  public failed_import = false

  @Watch('imported_file_row_keys')
  public onImportedFileKeyChange(val: any) {
    if (val) {
      const use_zipcode = val.includes('zip_postal_code') || val.includes('zipcode')

      let zipcode_index = this.loaded_data_fields.findIndex(f => f.key === 'zipcode')
      let range_index = this.loaded_data_fields.findIndex(f => f.key === 'range')

      this.loaded_data_fields[zipcode_index].show = use_zipcode
      this.loaded_data_fields[range_index].show = use_zipcode
    }
  }

  @Watch('all_selected')
  public onSelectedChange(val: any) {
    if (!val) {
      this.resetSelection()
    } else {
      // @ts-ignore
      this.selected_confirm_row = this.loaded_data.map(i => i.id)
    }
  }

  public get masks() {
    return { currency: currencyMask, customNumber }
  }

  public get show_imported_table_fields() {
    return this.loaded_data_fields.filter(f => f.show)
  }

  public get computedImportedData() {
    if (!this.loaded_data || !this.loaded_data.length) return []

    return this.loaded_data.map((data: any) => {
      if (!data.selected_dma && !data.confirmed) {
        data._rowVariant = 'warning'
      }
      if (!data.selected_dma ? !data.confirmed : !data.confirmed) {
        data._rowVariant = 'warning'
      } else {
        data._rowVariant = ''
      }
      return data
    })
  }

  public get indeterminate(): boolean {
    return (
      this.selected_confirm_row.length > 0
      && this.selected_confirm_row.length < this.loaded_data.length
    )
  }

  public get canConfirmDmaSelections(): boolean {
    return !this.computedImportedData.some(i => !i.confirmed)
  }

  public readLoadedData() {
    this.table_loading = true

    return new Promise((resolve, reject) => {
      this.table_loading = false

      setTimeout(() => resolve(this.computedImportedData), 500)
    })
  }

  /**
   * input : "0171 AUSTIN, TX CVG=.86",
   * outputs : AUSTIN TX CVG
   *
   * @param name
   */
  public formatDMA(name: string) {
    return name
      .replace(/[^a-zA-Z\s]/g, ' ')
      .replace(/\s+/g, ' ')
      .toLowerCase()
  }

  public importFile(files: FileList) {
    this.imported_file_row_keys = null
    if (!files || files.length == 0) return

    if (files.length > 1) {
      WebMessage.error('You can only upload one order file at a time.')
      return
    }

    let file = files[0]

    if (file) {
      this.processFile(file)
    }
  }

  public reMapRowObjKeys(row: any, index: number = 0) {
    if (index == 0) {
      this.imported_file_row_keys = Object.keys(row).map(key => {
        // check if first char is underline
        if (key[0] == '_' || key[key.length - 1] == '_') {
          // remove first char
        }

        return key
          .trim()
          .toLowerCase()
          .replace(/[^a-z]+/gi, ' ')
          .replace(/\s+/g, '_')
      })
    }

    return Object.keys(row).reduce((acc: any, item) => {
      const key = item
        .trim()
        .toLowerCase()
        .replace(/[^a-z]+/gi, ' ')
        .replace(/\s+/g, '_')

      acc[key] = row[item]
      return acc
    }, {})
  }

  public checkRequriedColumns(row: any) {
    const errors = []
    if (typeof row.client === 'undefined') {
      errors.push('Client')
    }

    if (typeof row.market === 'undefined') {
      errors.push('Market')
    }

    if (typeof row.start_at === 'undefined') {
      errors.push('Start At')
    }

    if (typeof row.end_at === 'undefined') {
      errors.push('End At')
    }

    if (typeof row.net_rate === 'undefined' && typeof row.gross_rate === 'undefined') {
      errors.push('Net Rate or Gross Rate')
    }

    if (typeof row.impressions === 'undefined' && typeof row.weekly_impressions === 'undefined') {
      errors.push('Impressions or Weekly Impressions')
    }

    // Optional Fields
    if (typeof row.client_code === 'undefined') {
      this.import_errors.push('Client Code is missing')
    }

    if (typeof row.product_code === 'undefined') {
      this.import_errors.push('Product Code is missing')
    }

    if (typeof row.estimate_code === 'undefined') {
      this.import_errors.push('Estimate Code is missing')
    }

    if (typeof row.spot_length === 'undefined') {
      this.import_errors.push('Spot Length is missing')
    }

    if (typeof row.sales_rep === 'undefined') {
      this.import_errors.push('Sales Rep is missing')
    }

    if (typeof row.media_package === 'undefined') {
      this.import_errors.push('Media Package is missing')
    }

    if (errors.length > 0) {
      this.import_error_type = 'danger'
    } else {
      this.import_error_type = 'warning'
    }

    return errors
  }

  private sales_reps: any = {}

  private media_packages: any = {}

  private convertExcekDate(date: number) {
    return new Date(Date.UTC(0, 0, date))
  }

  public processFile(file: any) {
    this.$emit('update:confirming', true)
    this.import_status = 'Loading file...'

    this.$bvModal.show('import-loader')
    this.loaded_data = []
    this.import_errors = []
    this.failed_import = false
    this.dma_list = []

    if (!this.value.agency_id) {
      this.import_errors.push('Please select an agency first.')
      this.import_error_type = 'danger'
      this.import_status = 'failed'
      this.failed_import = true
      return
    }

    let reader = new FileReader()
    reader.onload = async (e: any) => {
      let bytes = new Uint8Array(e.target.result)

      this.file_name = file.name

      /* read workbook */
      let wb = read(bytes)

      if (wb.SheetNames.length != 1) {
        this.import_errors.push('You should have only one sheet in the file.')
        this.import_status = 'failed'
        this.failed_import = true
        return
      }

      let data = utils.sheet_to_json<any>(wb.Sheets[wb.SheetNames[0]])

      this.import_status = 'Validating file...'

      if (!data || !data.length) {
        this.import_errors.push('No data found in the file')
        this.import_status = 'failed'
        this.failed_import = true
        return
      }

      // Normalize keys
      data = data.map((row: any, index: number) => this.reMapRowObjKeys(row, index))

      const errors = this.checkRequriedColumns(data[0])
      if (errors.length > 0) {
        this.import_errors.push(`Missing required columns: ${errors.join(', ')}`)
        this.import_status = 'failed'
        this.failed_import = true
        return
      }

      this.import_status = 'Extracting data...'

      let total_cols: any = null

      // Isolate sales reps
      let sales_reps = data
        .filter((row: any) => typeof row.sales_rep !== 'undefined' && row.sales_rep != '')
        .map((row: any) => row.sales_rep)
      // Remove duplicates
      sales_reps = [...new Set(sales_reps)]

      // Search for sales reps
      for (let rep of sales_reps) {
        if (rep) {
          const user = await User.searchOptions({ search: rep })
          if (user && user.length) {
            this.sales_reps[rep] = user[0].value
          }
        }
      }

      // Isolate media packages
      let media_packages = data
        .filter((row: any) => typeof row.media_package !== 'undefined' && row.media_package != '')
        .map((row: any) => row.media_package)
      // Remove duplicates
      media_packages = [...new Set(media_packages)]

      // Search for media packages
      for (let media of media_packages) {
        if (media) {
          const media_package = await MediaPackage.searchOptions({ search: media })
          if (media_package && media_package.length) {
            this.media_packages[media] = media_package[0].value
          }
        }
      }

      data.forEach((row: any, index: number) => {
        if (!row) return
        if (total_cols === null && Object.keys(row).length > 1) {
          total_cols = Object.keys(row).length
        }

        index += 2

        let search_col = null

        if (this.imported_file_row_keys.includes('dma_name')) {
          // 'has dma col in sheet'
          search_col = row.dma_name
        } else {
          search_col = row.market
        }

        let dma_item: any = this.findDMA(search_col, row, index)

        if (dma_item) {
          this.loaded_data.push(dma_item)
          dma_item._rowVariant = 'warning' // starts indicating that the row was not checked
        }
      })

      this.import_status = 'done'
    }
    reader.readAsArrayBuffer(file)

    // force clear input.type=file in the dom

    this.reload_drop_down_area = true

    setTimeout(() => {
      this.reload_drop_down_area = false
    }, 200)
  }

  public resetSelection() {
    this.$set(this, 'selected_confirm_row', [])
  }

  public get dmas_source() {
    return dmas
  }

  public findDMA(cell_value: any, row: any = null, index: number) {
    // block the code, make validation from the parent that called this function
    if (!cell_value) return null

    const options = {
      includeScore: true,
      keys: ['name', 'state'],
      threshold: 0.65,
      location: 1,
      findAllMatches: true,
      includeMatches: true,
    }

    this.dma_list = this.dmas_source.map(d => {
      d.name = this.formatDMA(d.name)
      d.value = d.id
      return d
    })

    let cell_values = this.formatDMA(cell_value)

    let fuse = new Fuse(this.dma_list, options)

    const result = fuse.search(cell_values)

    return this.buildRow({
      index,
      row,
      result,
      cell_value,
    })
  }

  /**
   * Builds the data used after import
   *
   * @param index
   * @param row
   * @param result
   * @param cell_value
   *
   */

  public getFormatedEstimate(row: any) {
    if (!row || !row.estimate) return 0
    let e = row.estimate.split(' ')
    if (e && e.length) return e[0]
  }

  public getFormatedClientProduct(row: any) {
    if (!row || !row.product) return ''
    let e = row.product.split(' ')
    if (e && e.length) return e.splice(1).toString().replaceAll(',', ' ')
  }

  /**
   *
   * The param row is from the imported sheet.
   *
   * It is the whole row object
   *
   * @param row
   */
  public buildRow({
    index, row, result, cell_value,
  }) {
    let client_name = row && row.client ? row.client : ''

    let net_rate = 0
    let gross_rate = 0
    if (row.net_rate) {
      net_rate = row.net_rate
      gross_rate = new BigNumber(net_rate).dividedBy(0.85).toNumber()
    } else if (row.gross_rate) {
      gross_rate = row.gross_rate
      net_rate = new BigNumber(gross_rate).multipliedBy(0.85).toNumber()
    }

    gross_rate = new BigNumber(net_rate).dividedBy(0.85).toNumber()

    let client_code = row.client_code ?? ''
    let product_code = row.product_code ?? ''
    product_code = product_code.split(' ')[0].trim()
    let estimate_code = ''
    if (row.estimate_code) {
      estimate_code = String(row.estimate_code)
    }
    estimate_code = estimate_code.split(' ')[0].trim()

    client_name = client_name.replaceAll(`${client_code} `, ' ').trim()

    let flight_start = moment().add(1, 'months').startOf('month').startOf('week')
      .add(1, 'day')

    if (row.start_at) {
      flight_start = moment(this.convertExcekDate(row.start_at)).startOf('day')
    }

    let flight_end = moment().add(1, 'months').endOf('month').endOf('week')
      .add(1, 'day')
    while (flight_end.format('dddd').toLocaleLowerCase() !== 'sunday') {
      flight_end = flight_end.subtract(1, 'day')
    }

    if (row.end_at) {
      flight_end = moment(this.convertExcekDate(row.end_at)).endOf('day')
    }

    let impressions = 0
    if (row.impressions) {
      impressions = row.impressions
    } else if (row.weekly_impressions) {
      let weeks = flight_end.diff(flight_start, 'weeks') + 1

      weeks = Math.ceil(weeks)
      impressions = row.weekly_impressions * weeks
    }

    let sales_rep_id = null

    if (row.sales_rep) {
      if (this.sales_reps[row.sales_rep]) {
        sales_rep_id = this.sales_reps[row.sales_rep]
      }
    }

    let media_package_id = null
    if (row.media_package) {
      if (this.media_packages[row.media_package]) {
        media_package_id = this.media_packages[row.media_package]
      }
    } else if (this.value.agency && this.value.agency.default_media_package_id) {
      media_package_id = this.value.agency.default_media_package_id
    }

    let demo_group = { age_low: '25', age_high: '54', target: 'AD' }
    if (row.demo_group) {
      let val = row.demo_group.trim().substring(0, 2).toUpperCase()
      if (val != 'AD') {
        val = val.substring(0, 1)
      }
      demo_group.target = val

      val = row.demo_group.replace(demo_group.target, '').split('-')
      demo_group.age_low = val[0].replaceAll('+', '')
      if (val[1]) {
        demo_group.age_high = val[1]
      } else {
        demo_group.age_high = '+'
      }
    }

    let ad_length = '30'
    if (row.spot_length) {
      // extract only the numbers
      ad_length = row.spot_length.replace(/\D/g, '')
    }
    if (row.days_of_week) {
      // convert days to full format
      const ref = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
      const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
      row.days_of_week = row.days_of_week
        .split(',')
        .map((d: string) => {
          let val = d.trim().toLowerCase()

          if (ref.includes(val)) {
            return days[ref.indexOf(val)]
          }

          return val
        })
        // remove invalid days
        .filter((d: string) => days.includes(d))
    }
    if (row.skip_weeks) {
      row.skip_weeks = row.skip_weeks.split(',').map((d: string) => d.trim().toLowerCase())
    }

    // console.log('row', row)

    return {
      id: index,
      name: cell_value,
      estimate: this.getFormatedEstimate(row),
      client_name,
      client_code,
      product_code,
      estimate_code,
      matches: result,
      selected_dma: this.getFirstMatch(result),
      confirmed: false,
      flight_start: flight_start.format('YYYY-MM-DD'),
      flight_end: flight_end.format('YYYY-MM-DD'),
      range: 0,
      zipcode: row.zipcode ?? [],
      range_type: 'range',
      options: this.makeOptions(result),
      impressions,
      sales_rep_id,
      media_package_id,
      gross_rate,
      net_rate,
      ad_length,
      demo_group,
      skip_weeks: row.skip_weeks || [],
      days_of_week: row.days_of_week || [],
    }
  }

  /**
   *
   * TODO add extra function to the matches
   * if the match is the same as the value from sheet cell
   * then we can return the match
   *
   * here we can use fusejs with a strict threshold to find the perfect match in a lower range of options if any
   */
  public getFirstMatch(matches: any) {
    if (!matches || !matches.length) return null
    if (matches.length > 1) return matches[0].item.id
    return matches[0].item.id ?? null
  }

  public makeOptions(matches: any) {
    return matches.map(m => {
      const name = m.item.name.toUpperCase()
      return {
        name: `${m.item.id} ${name}`,
        value: m.item.id,
      }
    })
  }

  public openModalToConfirmData() {
    if (this.import_status === 'failed') {
      return
    }
    this.loading_data = true

    setTimeout(() => {
      this.confirm_data = true
    }, 500)

    setTimeout(() => {
      this.table_loading = false
      this.loading_data = false
    }, 1000)
  }

  public confirmSelected() {
    this.loaded_data = this.loaded_data.map((data: any) => {
      if (data.selected_dma) {
        data._rowVariant = ''
        data.confirmed = true
      }

      return data
    })
    this.all_selected = false
  }

  public confirmZipcodeRange(data: any, split_codes: boolean = false) {
    const { zipcode, range } = data
    if (!zipcode) return []
    return this.formatZipcodes(zipcode).map(code => ({
      [code.trim()]: range,
    }))
  }

  public confirmChanges() {
    const d = this.value
    d.items = [] // reset items, remove the empty default one
    this.computedImportedData.forEach(data => {
      let newItem = new PrePlanItem()

      let obj = PrePlanItem.toObject({
        ...newItem,
        estimate_code: data.estimate_code,
        client_name: data.client_name,
        client_code: data.client_code,
        product_code: data.product_code,
        ad_length: data.ad_length,
        gross_rate: data.gross_rate,
        net_rate: data.net_rate,
        impressions: data.impressions,
        client_product: data.client_product,
        flight_start: data.flight_start,
        flight_end: data.flight_end,
        targetting: {
          ...newItem.targetting,
          include_dmas: [data.selected_dma],
          include_zips: this.formatZipcodes(data.zipcode),
          zipcode_range: this.confirmZipcodeRange(data),
        },
        sales_rep_id: data.sales_rep_id,
        media_package_id: data.media_package_id,
        demo_groups: [data.demo_group],
        visible: false,
        skip_weeks: data.skip_weeks,
        days_of_week: data.days_of_week,
      })

      d.addItem(obj)
    })

    this.$emit('input', d)

    this.confirm_data = false
    this.loaded_data = []
    this.$emit('update:confirming', false)
  }

  public formatZipcodes(codes: String, return_first: boolean = false) {
    if (!codes || !codes.length) return return_first ? '' : []
    let splited = codes.split(/[, \n]+/)
    if (return_first && splited && splited.length) return splited[0]

    return splited
  }

  public addExtraZipcodes(item: any) {
    this.$root.$emit('bv::hide::popover')

    Vue.set(this, 'temp_zipcodes', item.zipcode)

    setTimeout(() => {
      this.$root.$emit('bv::show::popover', `popover-component-${item.id}`)
    }, 100)
  }

  public cancelExtraZipcodes() {
    this.$root.$emit('bv::hide::popover')
    this.temp_zipcodes = null
  }

  public confirmExtraZipcodes(index: any) {
    this.computedImportedData[index].zipcode = this.temp_zipcodes

    setTimeout(() => this.cancelExtraZipcodes(), 500)
  }

  public searchAllDma(index: number) {
    this.dma_options_index.push(index)
  }

  public created() {
    this.dma_full_list = clone(this.dmas_source).map(
      d => new SelectOption(`${d.id} ${d.name.toUpperCase()}`, d.id),
    )
  }

  public selectedItemFromDmaList(data: any) {
    data.item.confirmed = false
  }
}
