import { ProductsFull, TASK_REQUEST_OPTION_TYPE } from '@/models/interface/Common'
import { currencyMask } from '@/models/interface/Masks'
import SelectOption from '@/models/interface/SelectOption'
import Task from '@/models/Task'
import User from '@/models/User'
import SystemtModule from '@/store/SystemModule'
import { getModule } from 'vuex-module-decorators'

const system = getModule(SystemtModule)

const user = getModule(SystemtModule).user

export default {
  name: 'Task',
  sections: [
    {
      name: 'Task',
      fields: [
        {
          name: 'Type',
          type: 'picker',
          target: 'type',
          rules: 'required',
          options: [
            new SelectOption('Custom', 'custom'),
            new SelectOption('Marketing Material [Marketing]', 'marketing_material'),
            new SelectOption('Request Media Plan', 'request_media_plan'),
            new SelectOption('Cancel Opportunity', 'cancel_opportunity'),
          ],
          disabled: () => user?.type === 'sales',
        },
        {
          name: 'Task Title',
          type: 'text',
          target: 'name',
          rules: 'required|min:3|max:255',
        },
        {
          name: 'Due At',
          type: 'datetime',
          target: 'due_at',
          clearable: true,
          rules: 'required',
        },
        {
          name: 'Format',
          type: 'picker',
          target: 'additional_information.format',
          rules: 'required',
          options: [
            new SelectOption('Deliverable', 'deliverable'),
            new SelectOption('One Sheet', 'one_sheet'),
            new SelectOption('Design Request', 'design_request'),
          ],
          visible: (task: Task) => task.type === 'marketing_material',
        },
        {
          name: 'Project Overview',
          type: 'text',
          target: 'additional_information.project_overview',
          rules: '',
          visible: (task: Task) => task.type === 'marketing_material',
        },
        {
          name: 'Objective',
          type: 'text',
          target: 'additional_information.objective',
          rules: '',
          visible: (task: Task) => ['marketing_material'].includes(task.type),
        },
        {
          name: 'Target Audience',
          type: 'text',
          target: 'additional_information.target_audience',
          rules: '',
          visible: (task: Task) => ['marketing_material'].includes(task.type),
        },
        {
          name: 'Agency',
          type: 'agency',
          target: 'additional_information.agency_id',
          rules: 'required',
          visible: (task: Task) => task.type === 'request_media_plan',
        },
        {
          name: 'Advertiser',
          type: 'advertiser',
          target: 'additional_information.advertiser_id',
          rules: 'required',
          visible: (task: Task) => task.type === 'request_media_plan',
        },
        {
          name: 'Products',
          type: 'picker',
          target: 'additional_information.products',
          rules: 'required',
          options: ProductsFull,
          multiple: true,
          visible: (task: Task) => task.type !== 'custom',
        },
        {
          name: 'Requests',
          type: 'picker',
          target: 'additional_information.requests',
          rules: 'required',
          multiple: true,
          options: TASK_REQUEST_OPTION_TYPE,
          visible: (task: Task) => task.type !== 'custom',
        },
        {
          name: ' Pipeline Value',
          mask: currencyMask,
          type: 'number',
          target: 'additional_information.pipeline_value',
          rules: 'required',
          visible: (task: Task) => task.additional_information.requests.includes('max_avail'),
        },
        {
          name: 'Key Messages',
          type: 'text',
          target: 'additional_information.key_messages',
          rules: '',
          visible: (task: Task) => task.type === 'marketing_material',
        },
        {
          name: 'Research',
          type: 'text',
          target: 'additional_information.research',
          rules: '',
          visible: (task: Task) => task.type === 'marketing_material',
        },
        {
          name: 'Mandatory Elements',
          type: 'text',
          target: 'additional_information.mandatory_elements',
          rules: '',
          visible: (task: Task) => task.type === 'marketing_material',
        },

        {
          name: 'Amount',
          target: 'additional_information.amount',
          rules: 'required',
          mask: currencyMask,
          visible: (task: Task) => task.type === 'request_media_plan',
        },

        {
          name: 'Flight start',
          type: 'date',
          target: 'additional_information.flight_start',
          clearable: true,
          rules: 'required',
          visible: (task: Task) => task.type === 'request_media_plan',
        },
        {
          name: 'Flight end',
          type: 'date',
          target: 'additional_information.flight_end',
          clearable: true,
          rules: 'required',
          visible: (task: Task) => task.type === 'request_media_plan',
        },
        {
          name: 'Targetting',
          type: 'geo_targetting',
          target: 'additional_information.targetting',
          class: 'my-4',
          pre_select: false,
          visible: (task: Task) => task.type === 'request_media_plan',
        },
        {
          name: 'Demo Groups',
          type: 'demo_builder',
          target: 'additional_information.demo_groups',
          horizontal: false,
          class: 'mb-4',
          visible: (task: Task) => task.type === 'request_media_plan',
        },
        {
          name: 'Additional Notes',
          type: 'html',
          target: 'additional_information.additional_notes',
          rules: '',
          visible: (task: Task) => task.type !== 'custom',
        },
        {
          name: 'Description',
          type: 'html',
          target: 'description',
          class: 'text-capitalize',
          visible: (task: Task) => task.type === 'custom',
        },
        {
          name: 'Assign To',
          type: 'user',
          target: 'target_id',
          rules: 'required',
          create: false,
          visible: (task: Task) => task.type === 'custom' || !!task.id,
        },
        {
          name: 'IO/Max Avails or Support files',
          type: 'model_file_upload',
          target: 'files',
        },
      ],
    },
  ],
}
