import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import EditTask from '@/pages/Task/components/EditTask.vue'
import Vue from 'vue'
import router from '@/Routes'
import Task from '../Task'
import WebMessage from '../WebMessage'
import SelectOption from '../interface/SelectOption'

const { user } = getModule(SystemtModule)

export const model_name = 'task'

export const querySettings = {
  user_fields: [
    {
      name: 'target',
      key: 'target_id',
      thClass: 'align-middle text-center',
      description: 'Filter by Assigned To',
    },
    {
      name: 'owner',
      key: 'owner_id',
      thClass: 'align-middle text-center',
      description: 'Filter by Owner',
    },
  ],
  custom_fields: [
    {
      name: 'is:mine',
      value: 'is:mine',
      description: 'Includes only records that are owned or assigned to you',
    },
    {
      name: 'is:owned',
      value: 'is:owned',
      description: 'Includes only records that are owned by you',
    },
    {
      name: 'is:assigned',
      value: 'is:assigned',
      description: 'Includes only records that are assigned to you',
    },
    {
      name: 'is_not:completed',
      value: 'is_not:completed',
      description: 'Includes only records that are not completed',
    },
    {
      name: 'is:due_today',
      value: 'is:due_today',
      description: 'Includes only records that are due today',
    },
    {
      name: 'is:due_tomorrow',
      value: 'is:due_tomorrow',
      description: 'Includes only records that are due tomorrow',
    },
    {
      name: 'is:due_this_week',
      value: 'is:due_this_week',
      description: 'Includes only records that are due this week',
    },
  ],
}

export const tableFields: any = [
  /* {
    key: 'checkbox',
    thClass: 'align-middle text-center',
    label: '',
    sortable: false,
    show: true,
    type: 'checkbox',
  }, */
  {
    key: 'id',
    thClass: 'align-middle text-center',
    label: 'ID',
    sortable: true,
    show: false,
    tdClass: 'align-middle',
  },
  {
    key: 'name',
    thClass: 'align-middle text-center',
    label: 'Name',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
  },
  {
    key: 'comments',
    thClass: 'align-middle text-center',
    label: 'Comments',
    filter: false,
    show: true,
    sortable: false,
    tdClass: 'align-middle text-center',
    thStyle: 'width:4em',
  },
  {
    key: 'type',
    thClass: 'align-middle text-center',
    label: 'Type',
    sortable: true,
    show: true,
    type: 'capitalize_replace',
    tdClass: 'align-middle text-center',
    filter_type: 'picker',
    options: [
      new SelectOption('Type', ''),
      new SelectOption('Custom', 'custom'),
      new SelectOption('RFP Request', 'rfp_request'),
      new SelectOption('Marketing Material', 'marketing_material'),
      new SelectOption('Request Media Plan', 'request_media_plan'),
    ],
  },
  {
    key: 'requests',
    thClass: 'align-middle text-center',
    label: 'Requests',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center text-capitalize',
  },
  {
    key: 'format',
    thClass: 'align-middle text-center',
    label: 'Format',
    sortable: true,
    show: false,
    tdClass: 'align-middle text-center text-capitalize',
  },
  {
    key: 'html_description',
    thClass: 'align-middle text-center',
    label: 'Description',
    sortable: true,
    show: false,
    tdClass: 'align-middle',
  },
  {
    key: 'owner',
    thClass: 'align-middle text-center',
    label: 'Owner',
    sortable: true,
    show: true,
    type: 'object',
    object: {
      key: 'owner',
      thClass: 'align-middle text-center',
      property: 'name',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'target',
    thClass: 'align-middle text-center',
    label: 'Assigned To',
    sortable: true,
    show: true,
    type: 'object',
    object: {
      key: 'target',
      thClass: 'align-middle text-center',
      property: 'name',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'status_name',
    thClass: 'align-middle text-center',
    label: 'Status',
    sortable: true,
    show: true,
    type: 'badge',
    color: (task: Task) => task.status_color,
    tdClass: 'align-middle text-center',
    filter_type: 'picker',
    options: [
      new SelectOption('Status', ''),
      new SelectOption('Pending', 'pending'),
      new SelectOption('In Progress', 'in_progress'),
      new SelectOption('Hold', 'hold'),
      new SelectOption('Completed', 'completed'),
    ],
  },
  {
    key: 'owner_model_type',
    thClass: 'align-middle text-center',
    label: 'Owner Model',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
  },
  {
    key: 'completed_by',
    thClass: 'align-middle text-center',
    label: 'Completed by',
    sortable: true,
    show: true,
    type: 'object',
    object: {
      key: 'completed_by',
      thClass: 'align-middle text-center',
      property: 'name',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'due_at',
    thClass: 'align-middle text-center',
    label: 'Due At',
    sortable: true,
    show: true,
    type: 'date',
    filter_type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'completed_at',
    thClass: 'align-middle text-center',
    label: 'Completed At',
    sortable: true,
    show: true,
    type: 'date',
    filter_type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'created_at',
    thClass: 'align-middle text-center',
    label: 'Created At',
    sortable: true,
    show: false,
    type: 'date',
    filter_type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'updated_at',
    thClass: 'align-middle text-center',
    label: 'Update At',
    sortable: true,
    show: true,
    type: 'date',
    filter_type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'action',
    thClass: 'align-middle text-center',
    label: '',
    sortable: false,
    show: true,
    type: 'action_list',
    tdClass: 'actions text-right',
    thStyle: { width: '240px' },
    actions: [
      // {
      //   // icon: 'dot-circle',
      //   icon: 'plus',
      //   title: 'Add sub task',
      //   event: 'add-sub-task',
      //   show: (task: Task) => !task.parent_id,

      // },
      {
        // icon: 'dot-circle',
        icon: 'project-diagram',
        title: 'Create Plan',
        event: 'create-plan',
        top_level: (task: Task) => task.type == 'request_media_plan',
        show: (task: Task) =>
          user
          && user.can('task', 'edit')
          && task.type == 'request_media_plan'
          && ['completed', 'pending', 'hold', 'in_progress'].includes(task.status),
        action: (task: Task, dataTable: any) => {
          task.status = 'in_progress'
          task.save().then(() => {
            task.createPlan()
          })
        },
      },
      {
        icon: 'book-open',
        title: 'Create Max Avail',
        event: 'create-max-avail',
        top_level: (task: Task) => task.additional_information && task.additional_information.requests && task.additional_information.requests.includes('max_avail'),
        show: (task: Task) =>
          user
          && user.can('task', 'edit')
          && task.type == 'request_media_plan'
          && ['completed', 'pending', 'hold', 'in_progress'].includes(task.status),
        action: (task: Task, dataTable: any) => {
          task.status = 'in_progress'
          task.save().then(() => {
            task.createMaxAvails()
          })
        },
      },
      {
        icon: 'search',
        top_level: true,
        title: 'View',
        event: 'view',
        show: (task: Task) => user && user.can('task', 'view'),
        action: (task: Task, dataTable: any) => {
          let Component = Vue.extend(EditTask)
          let instance = new Component({
            propsData: {
              id: task.id,
              dataTable,
            },
          })
          instance.$mount()
        },
      },
      {
        icon: 'plus',
        top_level: true,
        title: 'Sub task',
        event: 'sub-task',
        show: (task: Task) => user && user.can('task', 'view') && !task.parent_id,
        action: (task: Task, dataTable: any) => {
          let Component = Vue.extend(EditTask)
          let instance = new Component({
            propsData: {
              dataTable,
              parentId: task.id,
              mode: 'edit',
              hideTypes: ['request_media_plan'],
              initialPayload: { type: 'marketing_material' },
            },
          })
          instance.$mount()
        },
      },
      {
        icon: 'pencil',
        top_level: true,
        title: 'Edit',
        event: 'edit',
        show: (task: Task) => user && user.can('task', 'view'),
        action: (task: Task, dataTable: any) => {
          let Component = Vue.extend(EditTask)
          let instance = new Component({
            propsData: {
              id: task.id,
              dataTable,
              mode: 'edit',
              parentId: task.parent_id || null,
            },
          })
          instance.$mount()
        },
      },
      {
        icon: 'play',
        title: 'Start',
        event: 'start',
        top_level: (task: Task) => task.status != 'in_progress',
        show: (task: Task) =>
          user
          && user.can('task', 'edit')
          && ['completed', 'pending', 'hold'].includes(task.status),
        action: (task: Task, dataTable: any) => {
          task.status = 'in_progress'
          task.save().then(() => {
            dataTable.refresh()
          })
        },
      },
      {
        icon: 'check',
        title: 'Complete',
        event: 'complete',
        top_level: (task: Task) => task.status == 'in_progress',
        show: (task: Task) =>
          user
          && user.can('task', 'edit')
          && ['in_progress', 'pending', 'hold'].includes(task.status),
        action: (task: Task, dataTable: any) => {
          task.status = 'completed'
          task.save().then(() => {
            dataTable.refresh()
          })
        },
      },
      {
        icon: 'pause',
        title: 'Hold',
        event: 'hold',
        top_level: (task: Task) => task.status == 'in_progress',
        show: (task: Task) =>
          user && user.can('task', 'edit') && ['in_progress', 'pending'].includes(task.status),
        action: (task: Task, dataTable: any) => {
          task.status = 'hold'
          task.save().then(() => {
            dataTable.refresh()
          })
        },
      },

      {
        icon: 'trash',
        title: 'Delete',
        event: 'delete',
        show: (task: Task) => user && user.can('task', 'edit'),
        action: (task: Task, dataTable: any) => {
          WebMessage.confirm(
            `Are you sure that you want to delete the Task "<strong>${task.name}</strong>"? You won't be able to restore it!`,
            'Delete Task',
          ).then((value: boolean) => {
            if (value) {
              task.delete().then(() => {
                dataTable.refresh()
              })
            }
          })
        },
      },
    ],
  },
]
