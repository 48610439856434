import Api from './Api'
import Company from './Company'
import PrePlanConversionItem from './PrePlanConversionItem'
import PrePlanItem from './PrePlanItem'
import WebMessage from './WebMessage'
import Model from './interface/Model'
import PaginateOptions from './interface/PaginateOptions'

export default class PrePlan extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'pre_plans' as string | null,
      plural: 'pre_plans' as string | null,
    },
  }

  public id: any = null

  public name: string = ''

  public agency_id: any = ''

  public agency: Company = new Company()

  public items: PrePlanItem[] = [new PrePlanItem()]

  public converted_items: PrePlanConversionItem[] = [] // used only at conversion table

  public original_name: any = ''

  public opportunity_id: any = null

  public addItem(data: any = null) {
    if (data) {
      data = Object.assign(new PrePlanItem(), data)
    }
    this.items.push(data || new PrePlanItem())
  }

  public removeItem(index: number) {
    this.items.splice(index, 1)
  }

  public get apiData() {
    return {
      id: this.id,
      name: this.name,
      agency_id: this.agency_id,
      items: this.items,
      opportunity_id: this.opportunity_id,
    }
  }

  public static download(params: any) {
    const api = new Api()

    return api.post(`pre_plans/${params.id}/export`, params)
  }

  public static getExcelData(id: any) {
    const api = new Api()

    return api.get(`pre_plans/${id}/generate-items`)
  }

  private static onSave(response: any) {
    WebMessage.success('Media Plans generated successfully.')

    return response
  }

  private static onError(error: any) {
    return error
  }

  public static createMediaPlans(id: any, data: any) {
    const api = new Api()

    return api
      .post(`pre_plans/${id}/generate-media-plans`, data)
      .then(data => {
        this.onSave(data)
        return data
      })
      .catch(error => {
        this.onError(error)
        return error
      })
  }

  public static async getListWhereIn(options: any) {
    let api = new Api()
    return api
      .get('pre_plans/search-list', options)
      .then(response => response.data)
      .catch(error => error)
  }

  public static async getMediaPlanListWhereIn(options: any) {
    let api = new Api()
    return api
      .get('pre_plans/mediaplan-search-list', options)
      .then(response => response.data)
      .catch(error => error)
  }

  public static async checkTask(id: string) {
    let api = new Api()
    return api
      .get(`tasks/${id}`)
      .then(response => response.data)
      .catch(error => error)
  }

  public toObject(source: any) {
    let instance = this.clone()

    if (source.items) {
      instance.items = PrePlanItem.toObjectList(source.items)
      delete source.items
    }

    Object.assign(instance, source)

    return instance
  }
}
